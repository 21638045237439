<template>
  <li ref="el" v-bind="apg.p(p.p)" :class="[apg.c(p.cl), p.p?.value && 'cursor-pointer']" :data-active="isActive" :data-tooltip-target="tt" :data-tooltip-placement="tp" @click="onClick">
    <template v-if="$slots.default || $slots.prepend || $slots.append">
      <slot name="prepend"></slot>
      <slot></slot>
      <slot name="append"></slot>
    </template>  
    <template v-else>
      <span>{{p.p?.label}}</span>  
    </template>  
  </li>
</template>
<script setup>
import { ref, onMounted, onUpdated, inject, watch, computed } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const apg = useAppeggio()
const el = ref()
const m = inject('modelValue', () => {})
const tt = p.p?.tipid ? p.p?.tipid : undefined
const tp = p.p?.tipplacement ? p.p?.tipplacement : undefined
const labelSetter = inject('itemLabel', () => {})
const listItemSelectHandler = inject('listItemSelectHandler', () => {})
const isActive = computed(() => p.p?.value && m?.value === p.p?.value)
function onClick($e) {
  if (m) m.value = p.p?.value
  listItemSelectHandler($e, { value: p.p?.value, label: p.p?.label })
}
watch(m, () => {
  if (isActive.value) {
    labelSetter?.(p.p?.label)
  }
}, { immediate: true })
</script>