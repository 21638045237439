<template>
  <label v-bind="a.p(p.p)" class="inline-flex items-center cursor-pointer" :class="dsbld ? ' cursor-not-allowed opacity-50' : ''">
    <input ref="r" type="checkbox" :id="i" :value="v" :class="a.c(p.cl)" v-model="m" :disabled="dsbld" :style="s">
    <slot></slot>
  </label>
</template>
<script setup>
import { ref, computed, onMounted, onUpdated } from 'vue'
import { useAppeggio } from '../../useAppeggio.js'
const emit = defineEmits(['update:modelValue'])
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String }, modelValue: { type: [String, Number, Boolean] } })
const i = p.p?.id ? p.p.id : 'checkbox' + p.p?.aid 
const v = p.p?.value !== undefined ? p.p.value : true 
const nv = p.p?.unchecked !== undefined ? p.p.unchecked : false
const s = p.p?.size ? { width: p.p.size + 'px', height: p.p.size + 'px' } : undefined
const r = ref()
const m = computed({
  get()  {
    return p.modelValue === v
  },
  set(a) {
    emit('update:modelValue', a ? v : nv)
  }
})
const isDsbld = _ => (p.p?.disabled !== undefined && p.p?.disabled !== false) 
const dsbld = ref(isDsbld())
onUpdated(_ => {
  dsbld.value = isDsbld()
})
onMounted(_ => {
  if (p.p?.on && r.value) r.value.click()
})
</script>