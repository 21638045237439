<script setup>

import { computed } from 'vue';


/* interface */

const props = defineProps({
  p: Object,
  process: Map,
});

const emit = defineEmits([
  'selected',
  'download',
]);


const modelValue = defineModel({
  type: String,
});


/* graph */

function mapToObject(m) {

  const result = {};

  for (const [k,v] of m) {
    if (v instanceof Map) {
      result[k] = mapToObject(v);
    }
    else {
      result[k] = v;
    }
  }

  return result;

}

const process = computed(() => {
  return mapToObject(props.process || props.p?.process || {});
});

const graph = computed(() => {

  const toKey = key => String(key).replaceAll(' ', '_');

  const nodeKeys = Object.keys(process.value);

  const nodes = nodeKeys.map(it => {

    if ([ 'Start', 'End' ].includes(it)) {
      return {
        key: toKey(it),
        label: it,
        shape: 'terminal',
        fill: '#101010',
        color: '#FEFEFE',
        strokeWidth: '2px',
        stroke: '#212121',
      };
    }

    return {
      key: toKey(it),
      label: it,
    };

  });

  const edges = Object.entries(process.value).flatMap(([ key, value ]) => {

    if (!value?.next || value === true) {
      return undefined;
    }

    if (Array.isArray(value?.next)) {
      return value?.next.map(i => ({
        from: toKey(key),
        to: toKey(i),
      }));
    }

    return {
      from: toKey(key),
      to: toKey(value?.next),
    };

  }).filter(Boolean);

  return {
    nodes,
    edges,
  };

});


/* template */

import EGraphRenderer from './EGraphRenderer.vue';

</script>


<template>
  <e-graph-renderer
    :graph="graph"
    v-model:selected-node="modelValue"
  />
</template>
