export { getFresh, tryCompile }

import axios from 'axios'
import { compile } from 'vue/dist/vue.esm-bundler.js'

function tryCompile(tpl) {
  try {
    return compile(tpl)
  } catch (err) {
    console.warn(err)
    console.warn(tpl)
  }
  return false
}

async function getFresh(app, server, root, path, { ETag, tpl, tpljs, title }) {
  console.log('getFresh', path)
  if (app && app.apgEd()) {
    let res = await app.apgEd().getFresh(server, root, path, { ETag, tpl, tpljs, title })
    if (res) return { ETag, tpl: res.tpl, tpljs: tryCompile(res.tpl), title: res.title }
  }
  let url = `${server}/${root}/isfreshis${path}`
  let headers = { 'Content-Type': 'text/plain' }
  if (ETag) headers['If-None-Match'] = ETag;
  let options = { headers }
  let args = [url, options]
  await axios['get'](...args)
    .then(r => {
      ETag = r.data.ETag
      tpl = r.data.tpl
      title = r.data.title
      tpljs = tryCompile(tpl)
      console.log('freshened', path)
    }).catch(e => {
      if (e?.response?.status !== 304) console.warn(e)
      else if (!tpljs) {
        tpljs = tryCompile(tpl)
      }
    })
  return { ETag, tpl, tpljs, title }
}
