<template>
  <Suspense v-if="p.p?.count === undefined">
    <a-loop :dm="p.p.dm" :aid="p.p.aid" :id=p.p.id :di="p.p.di" :querydata="p.p?.querydata">
      <template v-for="(_, name) of $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </a-loop>
  </Suspense>
  <template v-else v-for="index in p.p.count" :key="index">
    <slot />
  </template>
</template>
<script setup>
import {useAppeggio} from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({p: {type: Object}, cl: {type: String}})
</script>