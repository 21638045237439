<template>
  <div :aid="p.p?.aid" class="relative" :class="a.c(p.cl)">
    <div class="a-video-stopper absolute top-0 right-0 w-1/2 h-1/2" />
    <iframe
      :src="`https://www.youtube.com/embed/${p.src}?cc_load_policy=3`"
      frameborder="0"
      class="h-full w-full"
    />
  </div>
</template>

<script setup>
// import { onMounted } from 'vue'
const p = defineProps({ p: { type: Object }, cl: { type: String }, src: { type: String } })
import { useAppeggio } from './useAppeggio.js'
const a = useAppeggio()

// const pr = defineProps({
//   id: { type: String },
//   alt: { type: String },
//   tabindex: { type: Number },
//   youtubeId: { type: String },
// })

// onMounted(_ => {
//   console.log('youtube - mounted', p)
//   console.log('trying', `https://www.youtube.com/embed/${p.src}?cc_load_policy=3`)
// })
</script>
