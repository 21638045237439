<template>
  <slot name="prepend"></slot>
  <template v-for="(item, idx) in d">
    <slot :dm="{ it: item, iti: idx, itc: d.length, sc: scoped(item), di: dataid(idx, true) }" :di="dataid(idx, false)" class="a-loop"></slot>
  </template>
  <slot name="append"></slot>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useAppeggio } from './useAppeggio.js'
import { sym } from '../../lib/utils.js'

const a = useAppeggio()
const p = defineProps({ dm: { type: Object }, aid: { type: Number }, id: { type: String }, di: { type: Number }, querydata: { type: String } })
const c = computed(_ => a.dModel(p.aid, p.id ? p.di : undefined))
const d = ref([])
const q = computed(_ => a.mg('OppRec'))

if (p.querydata) watch(q, v => {
  // console.log('ALoop - q')
  if (p.querydata !== 'OppSvcs') return;
  let it = a.li([sym('find-service-lines-sync'), v])
  a.le([sym('database-read'), "ServiceLine", it])
      .then(ans => {
        console.log('ALoop special')
        d.value = ans.get('data')
      })
}, { immediate: true })

const dataid = (idx, inc) => {
  return `ld${a.nextCount(inc)}-${idx}`
}

const scoped = v => {
  if (!p.id) return undefined
  let it = p.dm && p.dm.sc ? p.dm.sc : {}
  it[p.id] = v
  return it
}

watch(c, v => {
  // console.log('ALoop - watch', p.aid, v)
  if (v && v.isMap && v.get('data')) d.value = v.get('data');
  if (v && Array.isArray(v)) d.value = v;
}, { immediate: true })

</script>
