<script setup>

import { computed } from 'vue';


/* interface */

const props = defineProps({
  graph: Object,
});

const emit = defineEmits([
  'node:click',
]);


const selectedNode = defineModel('selectedNode', {
  type: String,
});


/* graph rendering */

import VueMermaidString from 'vue-mermaid-string';

const DEFAULT_NODE_STYLES = {
  'fill': 'white',
  'color': 'black',
  'font-weight': 'normal',
  'stroke-width': '1px',
  'stroke': 'black',
};

const SELECTED_NODE_STYLES = {
  'fill': '#1d4ed8',
  'color': '#FAFAFA',
  'font-weight': 'normal',
  'stroke-width': '1px',
  'stroke': '#212121',
};


const graphString = computed(() => {

  const nodes = props.graph?.nodes ?? [];
  const edges = props.graph?.edges ?? [];

  const lines = [];

  lines.push('graph TD');

  for (const node of nodes) {

    const styles = { ...DEFAULT_NODE_STYLES };

    if (node.color) styles.color = node.color;
    if (node.fill) styles.fill = node.fill;
    if (node.fontWeight) styles['font-weight'] = node.fontWeight;
    if (node.strokeWidth) styles['stroke-width'] = node.strokeWidth;
    if (node.stroke) styles.stroke = node.stroke;

    if (node.key === selectedNode.value) {
      Object.assign(styles, SELECTED_NODE_STYLES);
    }

    lines.push(`style ${node.key} ${Object.entries(styles).map(it => `${it[0]}:${it[1]}`).join(',')}`);

    lines.push(`    ${node.key}@{ shape: ${node.shape || 'rect'}, label: "${node.label}" }`);

  }

  for (const edge of edges) {
    lines.push(`    ${edge.from} ${edge.label ? `--${ edge.label }--` : '--'}> ${edge.to}`);
  }

  return lines.join('\n');

});

function handleClick(event) {

  let current = event.target;
  let foundElement;


  while (current) {

    if (current.classList.contains('node') && current.classList.contains('default')) {
      foundElement = current;
      break;
    }

    current = current.parentElement;

  }


  const parts = foundElement?.getAttribute?.('id')?.split?.('-');
  const nodeKey = parts?.[1];

  const node = props.graph?.nodes?.find(it => it.key === nodeKey);

  if (!node) {
    return;
  }


  emit('node:click', node);
  selectedNode.value = node.key;

}

</script>


<template>
  <vue-mermaid-string
    :value="graphString"
    :options="{ flowchart: { nodeSpacing: 32, rankSpacing: 32, padding: 8, useMaxWidth: false } }"
    @click="handleClick"
  />
</template>
