<template>
  <img :src="source" class="circular--square" :class="a.c(p.cl)" />
</template>

<script setup>
import { computed } from 'vue'
import { useAppeggio } from './useAppeggio.js'

import md5 from 'md5'

const p = defineProps({ size: { type: [String, Number] }, email: { type: String }, cl: { type: String } })
const a = useAppeggio()

const source = computed(() => {
  let hash = md5(p?.email || 'anonymous@appeggio.com')
  let finalSize = Number(p?.size || 42)
  if (finalSize < 24) {
    finalSize = 24
  }
  if (finalSize > 2048) {
    finalSize = 2048
  }
  return `https://secure.gravatar.com/avatar/${hash}?r=g&s=${finalSize}&d=retro`
})
</script>

<style>
.circular--square { border-radius: 50%; }
</style>
