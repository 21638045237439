<template>
  <Icon :icon="s" :class="[a.c(p.cl), 'shrink-0']" :rotate="p.p?.rotate" :data-popover-target="popi" :aid="p.p?.aid"/>
</template>
<script setup>
import { computed, onMounted } from 'vue'
import { Icon } from '@iconify/vue' 
import { useAppeggio } from '../../useAppeggio.js'
const a = useAppeggio()
const p = defineProps({ p: { type: Object }, cl: { type: String } })
const s = computed(_ => p.p?.src ? p.p.src : 'mdi:emoticon-confused-outline')
const popi = p.p?.popi ? p.p?.popi : undefined
onMounted(_ => {
  if (popi !== undefined) a.fbInit('popovers')
})
</script>