export { createBrowserListeners }

function createBrowserListeners(apg, resize) {
  // if (ResizeObserver) new ResizeObserver(this.winResize).observe(this.tableContent)
  window.addEventListener('resize', resize)
  window.addEventListener('beforeunload', function(e) {
    window.removeEventListener('resize', resize)
    let x = 500
    let a = (new Date()).getTime() + x
    let b = false
    if (apg) apg.unloading(e, _ => b = true)
    while ((new Date()).getTime() < a && !b) {
      console.log('unloading', b)
    }
  }, false)
}
